import axios from "axios";
import EventBus from "./EventBus";
import {EventBusEventsEnum} from "./enums/EventBusEventEnums";
import { CustomSnackbarTypes } from "./components/CustomeSnackBar";

let Strapihttp = axios.create({
    baseURL: process.env.REACT_APP_STRAPI_BASE_URL
});


Strapihttp.interceptors.response.use(
    (res) => res,
    async (err) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: err?.response?.data?.error,
            type: CustomSnackbarTypes?.ERROR
        });
    }
)

export default Strapihttp;