import { ComponentNames } from "../enums/ComponentName";
import CardContainer from "./CardContainer";
import CollapsibleCardContainer from "./CollapsibleCardContainer";
import DownloadAppContainer from "./DownloadAppContainer";
import HeroContainer from "./HeroConatiner";
import HorizontalCardContainer from "./HorizontalCardContainer";
import BannerContainer from "./ImageContainer";
import TestimonialCarousel from "./TestimonialCarousel";
import Footer from "../components/Footer";

export default function BuilderFunction(props: {item: any}) {
    switch (props?.item?.key) {
        case ComponentNames.BANNER:
            return <BannerContainer item={props?.item}/>;
        case ComponentNames.CARD_CONTAINER:
            return <CardContainer item={props?.item}/>;
        case ComponentNames.COLLAPSIABLE_CARD_CONTAINER:
           return <CollapsibleCardContainer item={props?.item}/>;
        case ComponentNames.HERO:
            return <HeroContainer item={props?.item}/>;
        case ComponentNames.HORIZONTAL_CARD_CONTAINER:
          return <HorizontalCardContainer item={props?.item}/>;
        case ComponentNames.DOWNLOAD_APP_CONTAINER:
          return <DownloadAppContainer item={props?.item}/>;
        case ComponentNames.TESTIMOIALS:
            return <TestimonialCarousel item={props?.item}/>
        case ComponentNames.FOOTER:
            return <Footer item={props?.item}/>
        default:
          return null;
      }
}
