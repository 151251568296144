import HttpClient from "../HttpClient";

export default class OneMealService {
    async createOneMeal(houseId: number, payload: any){
        const res = await HttpClient.post(`/customers/one-meal-service/houses/${houseId}`, payload);
        return res?.data;
    }

    async getOneMealPrice(){
        const res = await HttpClient.get(`/customers/one-meal-service/global-price`);
        return res?.data;
    }

}
