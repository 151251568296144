import React, {useEffect, useState} from 'react'
import CustomerService from '../../services/customerService';
import classes from './Styles.module.css'
import {FormControl, MenuItem, Select, TextField} from '@mui/material'
import HouseService from '../../services/houseService';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {TimeEnums} from '../../enums/TimeEnums';
import {Address} from '../../interfaces/address/Address.interface';
import moment from 'moment';
import {GetOkaneLinkCreator} from '../../utils/utils';
import EventBus from '../../EventBus';
import {EventBusEventsEnum} from '../../enums/EventBusEventEnums';
import {FirebaseCleverTabRecordEvent, FirebaseCleverTabSetProfile} from '../../firebase';
import {EventNames} from '../../firebase/EventNames';
import {ScreenNames} from '../../firebase/ScreenNames';
import OneMealService from '../../services/oneMealService';
import CampaignService from '../../services/campaignService';
import { OkaneServiceNameEnums } from '../../enums/OkaneServiceNameEnums';
import CircularProgress from "@mui/material/CircularProgress";
import PaymentModal from "../Modals/PaymentModal";
import { CustomSnackbarTypes } from '../CustomeSnackBar';
import { Area } from '../../interfaces/address/Area.interface';
require('polyfill-object.fromentries');
declare const gtag: any;

const customerService = new CustomerService();
const houseService = new HouseService();
const oneMealService = new OneMealService();
const campaignService = new CampaignService()

export default function ChefItForm() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());
    const isSmallScreen = useSelector((state: RootState) => state.common.value.isSmallScreen)

    const customerDetails: any = useSelector((state: RootState) => state?.customer?.value)
    const addressDetails: any = useSelector((state: RootState) => state.address?.value)
    const houseDetails: any = useSelector((state: RootState) => state?.house?.value)

    const [name, setName] = useState<string>("");
    const [mobile, setMobile] = useState<string>("")
    const [otp, setOtp] = useState<string>("")
    const [otpResponse, setOtpResponse] = useState<boolean>(false)
    const [verifyOtp, setVerifyOtp] = useState<any>(null)

    const [date, setDate] = useState<any>(new Date())
    const [buildingNumber, setBuildingNumber] = useState<string>("")
    const [visit, setVisit] = useState<any>("")
    const [areaId, setAreaId] = useState<any>("")
    const [localityId, setLocalityId] = useState<any>("")
    const [cityId, setCityId] = useState<any>("")
    const [activeHouse, setActiveHouse] = useState<Address | null>(null);
    const [landmark, setLandMark] = useState<any>("")
    // const [servicePrice, setServicePrice] = useState<any>("")
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);
    const [paymentLink, setPaymentLink] = useState<string>('');

    const getOtpHandle = async () => {
        if (mobile?.length < 10 || mobile?.length > 10 || name?.length === 0) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Please fill mobile number and name properly',
                type: CustomSnackbarTypes?.ERROR
            });
            return
        }
        setOtp("")
        const res = await customerService.getOtp(mobile);
        FirebaseCleverTabRecordEvent(EventNames.USER_GET_OTP, {
            screenName: ScreenNames.CHEFIT_ONE_MEAL_PAGE,
        });
        if (res) {
            setOtpResponse(true);
        }
    };

    // const getPrice = async () => {
    //   // let price = "299", res: any;
    //   // res = await oneMealService.getOneMealPrice()
    //   // price = res?.OMS_GLOBAL_PRICE;
    //   // setServicePrice(price)
    // }

    const verifyOtpHandle = async () => {
        if (otp) {
            try {
                let verifiedRes = await customerService.verifyOtp(mobile, otp);
                gtag('event', 'conversion', {'send_to': 'AW-443347593/Cs_ZCKSLzY0YEInls9MB'})
                setVerifyOtp(verifiedRes);
                FirebaseCleverTabRecordEvent(EventNames.USER_VERIFY_OTP_CLICKED, {
                  screenName: ScreenNames.CHEFIT_ONE_MEAL_PAGE,
                });
                let customer = await customerService.getCustomerDetails()
                let nameArray = name?.split(" ");
                let fname = nameArray[0];
                let lname = "";
                if (nameArray?.length > 1) {
                    lname = nameArray[nameArray.length - 1];
                }
                customer = await customerService.updateCustomerDetails(fname, lname);
                customer && FirebaseCleverTabSetProfile(customer)
                if (Object.keys(queryParams)?.length > 0) {
                    campaignService.postCampaign({data: queryParams})
                }

            } catch (err) {
                console.log(err);
            }
        }
    };

    const getAllCity = async () => {
        await houseService.getAllCities();
    }

    const cityHandler = async (e: any) => {
        setLocalityId('')
        setAreaId('')
        setCityId(e.target.value)
        await houseService.getAllAreaForCity(e.target.value);
    }

    const areaHandler = async (e: any) => {
        setLocalityId('')
        setAreaId(e.target.value)
        await houseService.getAllLocalityForArea(e.target.value);
    }
    const localityHandle = async (e: any) => {
        setLocalityId(e.target.value);
    }

    const handlePayNow = async () => {
        FirebaseCleverTabRecordEvent(EventNames.USER_CLICKED_PAY, {
          screenName: ScreenNames.CHEFIT_ONE_MEAL_PAGE,
        });
        if (!visit || !cityId || !areaId || !localityId || !buildingNumber) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Please fill the required Details',
                type: CustomSnackbarTypes?.ERROR
            });
            return;
        }

        setIsLoading(true);

        let house;
        let cityName = addressDetails?.allCities?.filter((e: any) => e.id === cityId)[0]
        if (houseDetails?.userHouse.length === 0) {
            house = await houseService.createHouse({
                address: buildingNumber,
                locality: '',
                areaId,
                cityId,
                localityId,
                city: cityName?.name
            })
        }

        if (activeHouse) {
            house = await houseService.editHouse(activeHouse.id!, {
                address: buildingNumber,
                locality: landmark || "",
                areaId,
                cityId,
                localityId,
                city: cityName?.name
            });
        }
        let service = await oneMealService.createOneMeal(house?.id, {
            date: moment(date).format("YYYY-MM-DD"),
            genderPreference: "ANY",
            time: visit,
            totalPeople: 4,
            adCampaignData:
              queryParams && Object.keys(queryParams)?.length > 0
                ? queryParams
                : null,
          });

        if (service) {
          let okaneLink = GetOkaneLinkCreator(
            customerDetails?.authDetails?.refreshToken,
            service?.id,
            OkaneServiceNameEnums.OMS_PAY
          );
          // window.location.replace(okaneLink);

            setIsPaymentModalOpen(true);
            setPaymentLink(okaneLink);
          resetForm();
        }
        setIsLoading(false);
    }
    const resetForm = () => {
        setName("");
        setMobile("");
        setOtp("");
        setOtpResponse(false);
        setVerifyOtp(null);
        setDate(new Date());
        setBuildingNumber("");
        setVisit("");
        setAreaId("");
        setLocalityId("");
        setCityId("");
        setActiveHouse(null);
        setLandMark("");
    }

    const getUserHouse = async () => {
        if (verifyOtp) {
            getAllCity()
            try {
                let userHouses = await houseService.getAllHouseForUser(
                    customerDetails?.authDetails?.user?.id
                );
                if (userHouses?.length > 0) {
                    let desireHouse = null;
                    for (const userHouse of userHouses) {
                        if (userHouse?.localityId) {
                            desireHouse = userHouse
                            break;
                        } else if (userHouse?.areaId) {
                            desireHouse = userHouse;
                            continue;
                        } else if (userHouse?.cityId) {
                            desireHouse = userHouse
                            continue;
                        } else {
                            desireHouse = userHouse
                        }
                    }
                    if (desireHouse) {
                        setActiveHouse(desireHouse)
                        if (desireHouse?.cityId) {
                            setCityId(desireHouse?.cityId)
                            try {
                                await houseService.getAllAreaForCity(String(desireHouse?.cityId));
                            } catch (err) {
                                console.log(err)
                            }
                        }
                        desireHouse?.cityId && await houseService.getAllAreaForCity(String(desireHouse?.cityId));
                        if (desireHouse?.areaId) {
                            setAreaId(desireHouse?.areaId)
                            try {
                                await houseService.getAllLocalityForArea(String(desireHouse?.areaId));
                            } catch (err) {
                                console.log(err)
                            }
                        }
                        setLocalityId(desireHouse?.localityId)
                        setLandMark(desireHouse?.locality)
                        desireHouse?.address && setBuildingNumber(desireHouse?.address)
                    }
                }
            } catch (err) {
                console.log(err)
            }
        }
    }
    useEffect(() => {
        if(customerDetails?.authDetails){
            setVerifyOtp(customerDetails?.authDetails);
            setOtpResponse(true);
        }
        getUserHouse()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verifyOtp])

    useEffect(() => {
      FirebaseCleverTabRecordEvent(EventNames.LANDING_PAGE_VIEW, {
        screenName: ScreenNames.CHEFIT_ONE_MEAL_PAGE
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let sortedAddress = addressDetails?.allArea?.map((a: any) => a).sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        // names must be equal
        return 0;
    })
     //filter serviceable area
    sortedAddress = sortedAddress?.filter((e:Area) => e.isServiceable)
    //filter serviceable locality
    let filterLocalities = addressDetails?.allLocality?.filter((e:Address) => e.isServiceable)

    return (
        <div
            style={{
                padding: isSmallScreen ? "32px 22px" : "32px 40px",
                boxShadow: "0px 0px 26px rgba(214, 214, 214, 0.6)",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "white",

                width: isSmallScreen ? 'auto' : '400px'
            }}
        >
            <PaymentModal open={isPaymentModalOpen} webPaymentLink={paymentLink} onClose={() => {setIsPaymentModalOpen(false)}}/>
            <p
                style={{
                    fontSize: isSmallScreen ? "22px" : "32px",
                    fontWeight: 700,
                    marginBottom: isSmallScreen ? "8px" : "24px",
                    marginTop: "0px",
                }}
            >
                Book Your Visit
            </p>
            <div style={{width: '100%'}}>
                {!verifyOtp && (
                    <>
                        <div>
                            <p style={styles.label}>Your Name*</p>
                            <FormControl fullWidth size={"medium"}>
                                <TextField
                                    id="name"
                                    value={name}
                                    disabled={otpResponse}
                                    variant="outlined"
                                    placeholder="Full Name"
                                    onChange={(e: any) => {
                                        setName(e.target.value);
                                    }}
                                    className={classes.mobileInput}
                                    inputProps={{
                                        style: {
                                            padding: 12
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <p style={styles.label}>Mobile Number*</p>
                            <FormControl fullWidth size={"medium"}>
                                <TextField
                                    type="number"
                                    id="mobile"
                                    disabled={otpResponse}
                                    onChange={(e: any) => {
                                        setMobile(e.target.value);
                                    }}
                                    className={classes.mobileInput}
                                    placeholder="10 digit phone no."
                                    inputProps={{
                                        style: {
                                            padding: 12
                                        }
                                    }}
                                />
                            </FormControl>
                            {otpResponse && <span style={styles.otpSendMsg}>OTP sent</span>}
                            {!otpResponse && (
                                <button
                                    style={styles.submitBtn}
                                    className={'btn'}
                                    disabled={mobile.length !== 10}
                                    onClick={() => {
                                        getOtpHandle();
                                    }}
                                >
                                    {otpResponse ? "Resend OTP" : "Proceed"}
                                </button>
                            )}
                        </div>
                    </>
                )}
                {!verifyOtp && otpResponse && (
                    <div>
                        <p style={styles.label}>Enter OTP*</p>
                        <FormControl fullWidth size={"medium"}>
                            <TextField
                                type="tel"
                                id="otp"
                                value={otp}
                                onChange={(e: any) => setOtp(e.target.value)}
                                className={classes.mobileInput}
                                placeholder="Enter the OTP"
                                inputProps={{
                                    style: {
                                        padding: 12
                                    }
                                }}
                            />
                        </FormControl>
                        <button
                            style={styles.submitBtn}
                            className={'btn'}
                            disabled={otp.length !== 6}
                            onClick={() => {
                                verifyOtpHandle();
                            }}
                        >
                            Verify OTP
                        </button>
                    </div>
                )}
                {verifyOtp && (
                    <>
                        <div>
                            {name && <p style={styles.welcomeHeading}>Hello {name}</p>}
                            <p style={styles.label}>Date of Booking*</p>
                            <FormControl fullWidth size={"medium"}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    {!isSmallScreen ? (
                                        <DesktopDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            value={date}
                                            onChange={(e) => setDate(e)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    ) : (
                                        <MobileDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            value={date}
                                            onChange={(e) => setDate(e)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    )}
                                </LocalizationProvider>
                            </FormControl>
                        </div>
                        <div>
                            <p style={styles.label}>Time of Visit*</p>
                            <FormControl fullWidth size={"medium"}>
                                <Select
                                    labelId="visitTime"
                                    id="visitTime"
                                    value={visit}
                                    label=""
                                    onChange={(e: any) => setVisit(e.target.value)}
                                >
                                    {Object.values(TimeEnums).map((e: any) => {
                                        return (
                                            <MenuItem key={e} value={e}>
                                                {e}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <p style={styles.label}>City*</p>
                            <FormControl fullWidth size={"medium"}>
                                <Select
                                    labelId="City"
                                    id="City"
                                    value={cityId}
                                    label=""
                                    onChange={cityHandler}
                                >
                                    {addressDetails?.allCities?.map((e: any) => {
                                        return (
                                            <MenuItem key={e.id} value={e.id}>
                                                {e.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <p style={styles.label}>Sector/Area*</p>
                            <FormControl fullWidth size={"medium"}>
                                <Select
                                    labelId="area"
                                    id="area"
                                    value={areaId}
                                    label=""
                                    onChange={areaHandler}
                                >
                                    {sortedAddress?.map((e: any) => {
                                        return (
                                            <MenuItem key={e.id} value={e.id}>
                                                {e.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <p style={styles.label}>Locality/Colony/Society*</p>
                            <FormControl fullWidth size={"medium"}>
                                <Select
                                    labelId="Locality"
                                    id="Locality"
                                    value={localityId}
                                    label=""
                                    onChange={localityHandle}
                                >
                                    {filterLocalities?.map((e: any) => {
                                        return (
                                            <MenuItem key={e.id} value={e.id}>
                                                {e.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <p style={styles.label}>House no/Flat no/Floor/Building*</p>
                            <FormControl fullWidth size={"medium"}>
                                <TextField
                                    id="houseNo"
                                    value={buildingNumber}
                                    variant="outlined"
                                    placeholder="House no/Flat no/Floor/Building"
                                    onChange={(e: any) => {
                                        setBuildingNumber(e.target.value);
                                    }}
                                />
                            </FormControl>
                        </div>
                        <button
                            type={"submit"}
                            style={styles.payButton}
                            className={'btn'}
                            disabled={!visit || !cityId || !areaId || !localityId || !buildingNumber}
                            onClick={handlePayNow}
                        >
                            {
                                isLoading ?
                                    <CircularProgress style={{'color': 'white'}}/>
                                    :
                                    <p style={{margin: 0}}>Book Now</p>
                            }
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}
const styles = {
    inputWrapper: {
        // maxWidth: '300px',
    },
    label: {
        fontSize: "15px",
        fontWeight: 600,
        color: '#52525C',
        margin: '20px 0px 8px 0px'
    },
    welcomeHeading: {
        textAlign: 'center' as 'center',
        fontWeight: 700,
        color: '#000000',
        fontSize: "20px"
    },
    input: {
        padding: '16px',
        width: '340px',
        fontSize: '16px',
        border: '2px solid #D9D9D9',
        borderRadius: '4px',
        outline: 'none'
    },
    inputSmall: {
        padding: '16px',
        width: '100%',
        fontSize: '16px',
        border: '2px solid #D9D9D9',
        borderRadius: '8px',
        outline: 'none'
    },
    payButton: {
        backgroundColor: '#000000',
        marginTop: '40px',
        padding: '10px',
        fontSize: '24px',
        lineHeight: '34px',
        borderRadius: '8px',
        fontWeight: '700',
        border: 'none',
        color: '#ffffff',
        minWidth: "100%",
        cursor: 'pointer',
        fontFamily: "Open Sans"
    },
    submitBtn: {
        backgroundColor: '#000000',
        marginTop: '25px',
        padding: '10px',
        fontSize: '20px',
        border: 'none',
        lineHeight: '24px',
        borderRadius: '8px',
        fontWeight: '700',
        color: '#ffffff',
        minWidth: "100%",
        cursor: 'pointer',
        fontFamily: 'Open Sans'
    },
    otpSendMsg: {
        color: "green",
        paddingLeft: "6px",
        fontSize: "14px",
        fontWeight: "600"
    },
}

