import {createSlice} from "@reduxjs/toolkit";

export const addressDetailsSlice = createSlice({
    name: 'customerDetails',
    initialState: {
        value: {
            allCities: null,
            allArea: null,
            allLocality: null,
            editAddressData: null,
            createNewAddress: null
        }
    },
    reducers: {
        setAllCites: (state, action) => {
            state.value.allCities = action.payload;
        },
        setAllArea: (state, action) => {
            state.value.allArea = action.payload;
        },
        setAllLocality: (state, action) => {
            state.value.allLocality = action.payload;
        },
        setEditAddressData: (state, action) => {
            state.value.editAddressData = action.payload;
        },
        setCreateNewAddress: (state, action) => {
            state.value.createNewAddress = action.payload;
        },
    }
})

export const {setAllCites, setAllArea, setAllLocality, setEditAddressData, setCreateNewAddress} = addressDetailsSlice.actions;

export default addressDetailsSlice.reducer;
