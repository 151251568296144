import React, {useState} from 'react'
import Icon from "../assets/DownArrow.png"
import HeadingComponent from './HeadingComponent/HeadingComponent';
import SubHeadingComponent from './HeadingComponent/SubHeadingCompoent';
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import { DescriptionDto } from '../interfaces/componentsDtos/DescriptionComp';

export default function CollapsibleHeadingComponent(props: { data: DescriptionDto }) {
    const [open, setOpen] = useState(false);
    const isSmallScreen = useSelector((state: RootState) => state.common.value.isSmallScreen)

    return (
        <div
            style={{
                ...styles.mainContainer,
                paddingTop: isSmallScreen ? '8px' : '30px',
                paddingBottom: isSmallScreen ? '12px' : '28px',
            }}
        >
            <div style={styles.headingContainer} onClick={() => setOpen((prev) => !prev)}>
                <HeadingComponent data={props?.data?.heading}/>
                <div
                    style={styles.sideBtn}
                >
                    <img src={Icon} style={
                        !isSmallScreen ? {
                            height: 16,
                            width: 28
                        } : {
                            height: 6.5,
                            width: 11
                        }
                    } alt={"minus-icon"}/>
                </div>
            </div>
            {open && (
                <div style={{marginTop: isSmallScreen ? "8px" : "20px"}}>
                    <SubHeadingComponent data={props.data.subHeading}/>
                </div>
            )}
        </div>
    );
}
const styles = {
    mainContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        width: '100%',
        borderBottom: '1px solid #E9E9E9'
    },
    headingContainer: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    sideBtn: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
}
