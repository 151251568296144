import Strapihttp from "../StrapiHttpClient";
export default class StrapiService {

    async getAllChefItComponent(id: string) {
        const res = await Strapihttp.get(`/api/chef-its/${id}?populate=deep`)
        return res.data;
    }

    async getAllCleanItComponent(id: string) {
        const res = await Strapihttp.get(`/api/clean-its/${id}?populate=deep`)
        return res.data;
    }

    async getLandingPageComponent(id: string) {
        const res = await Strapihttp.get(`/api/landing-pages/${id}?populate=deep`)
        return res.data;
    }

    async getImages(link: string) {
        const res = await Strapihttp.get(`${link}`)
        if(res?.status === 200){
            return res?.data;
        }
        return null;
    }
}
