
export default function HeroContainer(props: { item: any }) {
  const components: any = props.item.value;
  let bannerImage = components?.heroImage?.data;
  let bannerImageUrl =
    bannerImage &&
    `${bannerImage?.attributes?.url}`;

  return (
    <div>
      {components && (
        <div>
          <img src={bannerImageUrl} style={styles.heroImage} alt={"hero-img"} />
        </div>
      )}
    </div>
  );
}
const styles = {
  heroImage: {
    width: "100%",
    height: "auto",
  },
};
