import HorizontalCard from "../components/HorizontalCard";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import HeadingComponent from "../components/HeadingComponent/HeadingComponent";
import FormBuilder from "./FormBuilder";

export default function HorizontalCardContainer(props: { item: any }) {
    let data = props?.item?.value;

    const isSmallScreen = useSelector((state: RootState) => state.common.value.isSmallScreen)

    let headersData = data.description;
    let widgetData = data.data;

    if (!data) {
        return null;
    }

    return (
        <div
            className={'common-container'}
            style={{
                marginTop: isSmallScreen ? '0px' : '100px',
                paddingBottom: isSmallScreen ? '46px' : '110px',
                backgroundColor: data.backgroundColor
            }}
        >
            <div
                style={{
                    maxWidth: isSmallScreen ? '100%' : '82%',
                    margin: isSmallScreen ? '0px 20px' : '0px',
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    justifyContent: 'space-between'
                }}
            >
                {
                    isSmallScreen &&
                    <div
                        style={{
                            transform: 'translateY(-20px)'
                        }}
                    >
                       <FormBuilder name={data?.formNameEnum} />
                    </div>
                }
                <div style={{marginTop: isSmallScreen ? '25px' : '-20px', maxWidth: '600px', marginRight: isSmallScreen ? "0px" : '60px'}}>
                    <HeadingComponent
                        data={headersData.heading}
                        inputStyles={{
                            textAlign: isSmallScreen ? 'center' : 'left'
                        }}
                    />
                    <HeadingComponent
                        data={headersData.subHeading}
                        inputStyles={{
                            marginTop: isSmallScreen ? '8px' : '20px',
                            textAlign: isSmallScreen ? 'center' : 'left'
                        }}
                    />
                    <div style={{marginTop: isSmallScreen ? '30px' : '68px'}}>
                        {
                            widgetData &&
                            Array.isArray(widgetData) &&
                            widgetData.map((item: any, index: number) => {
                                return <HorizontalCard data={item} key={index}/>;
                            })
                        }
                    </div>
                </div>
                {!isSmallScreen &&
                    <div
                        style={{
                            transform: 'translateY(-120px)'
                        }}
                    >
                        <FormBuilder name={data?.formNameEnum} />
                    </div>
                }
            </div>
        </div>
    );
}
