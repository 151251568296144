import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import HeadingComponent from "../components/HeadingComponent/HeadingComponent";
import Slider from "react-slick";
import React from "react";

const carouselSettings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
};

export default function TestimonialCarousel(props: {item: any}) {
    const components: any = props.item.value;
    const isSmallScreen = useSelector((state: RootState) => state.common.value.isSmallScreen)

    return (
        <div className={'common-container'}
             style={{
                 paddingTop: isSmallScreen ? '36px' : "128px",
                 paddingBottom: isSmallScreen ? '16px' : "60px",
                 backgroundColor: components.backgroundColor
             }}
        >
            <div
                style={{
                    maxWidth: isSmallScreen ? '95%' : '75%',
                    width: '100%',
                    marginBottom: '20px'
                }}
            >
                <HeadingComponent
                    data={components.heading}
                    inputStyles={{textAlign: 'center', width: '100%', marginBottom: isSmallScreen ? '12px' : '20px'}}
                />
                <HeadingComponent
                    data={components.subHeading}
                    inputStyles={{textAlign: 'center', width: '100%', marginBottom: isSmallScreen ? '16px' : '60px'}}
                />
                <div>
                    <Slider {...carouselSettings}>
                        {
                            components &&
                            components.data &&
                            Array.isArray(components.data) &&
                            components.data.length>0 &&
                            components.data.map((item: any) => {
                                return (
                                    <div key={item.id}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                padding: isSmallScreen ? '14px 16px' : '86px 80px',
                                                border: isSmallScreen ? '1px solid #E7E7E7' : '4px solid #E7E7E7',
                                                borderRadius: '20px',
                                                margin: isSmallScreen ? '0px 6px 12px 6px' : '0px 12px 36px 12px'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    marginRight: isSmallScreen ? '16px' : '60px',
                                                }}
                                            >
                                                <HeadingComponent data={item.userView} inputStyles={{marginBottom: isSmallScreen ? '8px' : '40px'}}/>
                                                <HeadingComponent data={item.userName}/>
                                            </div>
                                            <img
                                                style={isSmallScreen ? styles.imageMin : styles.imageMax}
                                                src={item?.userImage?.image?.data?.attributes?.url}
                                                alt={"icons"}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
};

const styles = {
    imageMax: {
        height: '302px',
        width: '298px',
        borderRadius: '50px'
    },
    imageMin: {
        height: '96px',
        width: '98px',
        borderRadius: '10px'
},
}
