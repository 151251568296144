import { FormNameEnums } from "../enums/FormNameEnums";
import ChefItForm from "../components/LoginForm/ChefItForm";
import CleanItForm from "../components/LoginForm/CleanItForm";

export default function FormBuilder(props: {name: FormNameEnums}) {
    switch (props?.name) {
        case FormNameEnums.CHEF_IT_FORM:
            return <ChefItForm />;
        case FormNameEnums.CLEAN_IT_FORM:
            return <CleanItForm/>;
        default:
          return <ChefItForm />
      }
}
