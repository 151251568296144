import React, {useEffect, useState} from 'react';
import EventBus from "../EventBus";
import {EventBusEventsEnum} from "../enums/EventBusEventEnums";
import {Alert, Snackbar} from "@mui/material";

export enum CustomSnackbarTypes {
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success'
}

export interface CustomSnackbarAttributes {
    open: boolean;
    duration: number;
    message: string;
    type: CustomSnackbarTypes;
}

const CustomSnackbar = () => {

    const [snackbarData, setSnackbarData] = useState<CustomSnackbarAttributes>({
        open: false,
        duration: 3000,
        type: CustomSnackbarTypes.SUCCESS,
        message: ''
    })

    useEffect(() => {
        const handleSnackbarShow = (data: CustomSnackbarAttributes) => {
            setSnackbarData((snackbarData) => ({
                ...snackbarData,
                ...data,
                open: true,
            }))
        }

        EventBus.getInstance().on(EventBusEventsEnum.SNACKBAR_SHOW, handleSnackbarShow);
        return () => {
            EventBus.getInstance().removeListener(EventBusEventsEnum.SNACKBAR_SHOW, handleSnackbarShow);
        }
    }, [])

    const handleClose = () => {
        setSnackbarData({
            open: false,
            message: '',
            type: CustomSnackbarTypes.SUCCESS,
            duration: 3000,
        })
    }

    return (
        <Snackbar open={snackbarData.open} autoHideDuration={snackbarData.duration} onClose={handleClose}>
            <Alert onClose={handleClose} severity={snackbarData.type} sx={{ width: '100%' }}>
                {snackbarData.message || 'Error'}
            </Alert>
        </Snackbar>
    )
};

export default CustomSnackbar;
