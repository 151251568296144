import {createSlice} from "@reduxjs/toolkit";

export const strapiSlice = createSlice({
    name: 'strapiComponent',
    initialState: {
        value: {
            getStrapiComponentsRes: null,
            getLandingPageRes: null,
        }
    },
    reducers: {
        setGetStrapiComponentRes: (state, action) => {
            state.value.getStrapiComponentsRes = action.payload;
        },
        setLandingPageComponentRes: (state, action) => {
            state.value.getLandingPageRes = action.payload;
        },
    }
})

export const { setGetStrapiComponentRes, setLandingPageComponentRes } = strapiSlice.actions;

export default strapiSlice.reducer;
