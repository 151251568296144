import axios from "axios";
import EventBus from "./EventBus";
import {EventBusEventsEnum} from "./enums/EventBusEventEnums";
import {retrieveFromSessionStorage} from "./utils/utils";
import { CustomSnackbarTypes } from "./components/CustomeSnackBar";

let http = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'X-APP-CODE': 'CUSTOMER'
    }
});

http.interceptors.request.use((req: any) => {
    let accessToken = retrieveFromSessionStorage("token");
    accessToken = !!accessToken ? accessToken : '';
    req.headers = {
        ...req.headers,
        'Authorization': `Bearer ${accessToken}`,
    }
    return req
})

http.interceptors.response.use(
    (res) => res,
    async (err) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: err?.response?.data?.error,
            type: CustomSnackbarTypes?.ERROR
        });
    }
)
export default http;