import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";
import clevertap from 'clevertap-web-sdk'
import Smartlook from 'smartlook-client'
import Customer from "../interfaces/customer";

const firebaseConfigProduction = {
    apiKey: "AIzaSyA1HK5ofYPRu_8kwWayVJg4nx0iVC_q3mU",
    authDomain: "lightyear-df7ef.firebaseapp.com",
    projectId: "lightyear-df7ef",
    storageBucket: "lightyear-df7ef.appspot.com",
    messagingSenderId: "1038928343466",
    appId: "1:1038928343466:web:714d4942f8f7a477293bd3",
    measurementId: "G-3NYPJ9BJCP"

}

const firebaseConfigDevelopment = {
    apiKey: "AIzaSyA1HK5ofYPRu_8kwWayVJg4nx0iVC_q3mU",
    authDomain: "lightyear-df7ef.firebaseapp.com",
    projectId: "lightyear-df7ef",
    storageBucket: "lightyear-df7ef.appspot.com",
    messagingSenderId: "1038928343466",
    appId: "1:1038928343466:web:714d4942f8f7a477293bd3",
    measurementId: "G-3NYPJ9BJCP"
}

const firebaseConfig = process.env.REACT_APP_ENV === 'production' ? firebaseConfigProduction : firebaseConfigDevelopment;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app);

const cleverTapConfigProduction = {
    ACCOUNT_ID: "8RK-Z9R-W56Z"
}

const cleverTapConfigDevelopment = {
    ACCOUNT_ID: "TEST-9RK-Z9R-W56Z"
}

const cleverTapConfig = process.env.REACT_APP_ENV === 'production' ? cleverTapConfigProduction : cleverTapConfigDevelopment;

const firebaseAnalytics = getAnalytics(app);

clevertap.privacy.push({optOut: false}) 
clevertap.privacy.push({useIP: false})  
clevertap.spa=true
clevertap.init(cleverTapConfig.ACCOUNT_ID)

Smartlook.init('f56eb0a0ada340d95bb7422a9a98461f939f03f9')

declare const fbq: any;

export const FirebaseCleverTabRecordEvent = (eventName: string, params?: {[key: string]: string | number | boolean}) => {
    clevertap.event.push(eventName, {...params})
    logEvent(firebaseAnalytics, eventName, params)
    fbq('trackCustom', eventName, {...params});
    if(params){
        Smartlook.track(eventName,params)
    }
    else{
        Smartlook.track(eventName,{"noparams":"noparams"})
    }
    
}

export const FirebaseCleverTabSetProfile = (customerProfile: Customer) => {
    const userProperties = {
        Name:
          customerProfile.fname +
            ' ' +
            (customerProfile.mname || '') +
            ' ' +
            customerProfile.lname || '',
        Identity: customerProfile?.id?.toString(),
        Email: customerProfile?.email ?? '',
        HouseId: customerProfile?.houses[0]?.houseId ?? '',
        Address: customerProfile?.houses[0]?.address ?? '',
        City: customerProfile?.houses[0]?.cityName ?? '',
        Locality: customerProfile?.houses[0]?.localityName ?? '',
        Mobile: customerProfile?.mobile,
        Location: customerProfile?.houses[0]?.cityName ?? '',
      };
    clevertap.onUserLogin.push({Site: userProperties})
    setUserProperties(firebaseAnalytics, userProperties)
    Smartlook.identify(customerProfile.id,userProperties)
}

//30 minutes
remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 30;

export default remoteConfig;