import CarouselCard from "../components/CarouselCard";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import HeadingComponent from "../components/HeadingComponent/HeadingComponent";
import React from "react";

export default function CardContainer(props: { item: any }) {
    const components: any = props.item.value;
    const isSmallScreen = useSelector((state: RootState) => state.common.value.isSmallScreen)

    return (
        <div
            className={'common-container'}
            style={{
                paddingTop: isSmallScreen ? '36px' : "94px",
                paddingBottom: isSmallScreen ? '40px' : "94px",
                backgroundColor: components.backgroundColor
            }}
        >
            <div
                style={
                    isSmallScreen ?
                        {
                            minWidth: '80%',
                            display: 'flex',
                            flexDirection: 'column'
                        }
                        :
                        {
                            maxWidth: '80%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column'
                        }
                }
            >
                <HeadingComponent
                    data={components.description.heading}
                    inputStyles={{textAlign: 'center', width: '100%', marginBottom: isSmallScreen ? '8px' : '20px'}}
                />
                <HeadingComponent
                    data={components.description.subHeading}
                    inputStyles={{textAlign: 'center', width: '100%'}}
                />
                {components && (
                    <div style={styles.carousel}>
                        {
                            components?.data &&
                            components?.data.map((e: any, i: number) => {
                                return <CarouselCard data={e} key={i}/>;
                            })
                        }
                    </div>
                )}
            </div>
        </div>
    );
}
const styles = {
    carousel: {
        display: "flex",
        flexWrap: "wrap" as "wrap",
        justifyContent: "space-evenly",
    },
};
