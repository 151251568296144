import { OkaneServiceNameEnums } from "../enums/OkaneServiceNameEnums";
import store from "../store/store";

export const saveToSessionStorage = (key: string, value: any, isJson: boolean = false) => {
    if (isJson === true) {
        JSON.stringify(value)
    }

    sessionStorage.setItem(key, value);
}

export const retrieveFromSessionStorage = (key: string) => {
    return sessionStorage.getItem(key);
}

export const FormatIncomingStyle = (style: any) => {
    let isSmallScreen = store?.getState()?.common?.value?.isSmallScreen;

    let compilerStyle : any = {};
    style && Object.keys(style).forEach(item => {
        if (isSmallScreen) {
            switch (item) {
                case 'mobileHeight':
                    compilerStyle['height'] = `${style[item]}px`;
                    break;
                case 'mobileWidth':
                    compilerStyle['width'] = `${style[item]}px`;
                    break;
                case 'mobileFontSize':
                    compilerStyle['fontSize'] = `${style[item]}px`;
                    break;
                case 'mobileFontWeight':
                    compilerStyle['fontWeight'] = `${style[item]}`;
                    break;
                default:
                    break;
            }
        } else {
            if(['fontSize', 'width', 'height'].includes(item)){
                compilerStyle[item] = `${style[item]}px`
            }
            if(['fontWeight'].includes(item)){
                compilerStyle[item] = `${style[item]}`
            }
        }
        if(item === 'backgroundColor'){
            compilerStyle['backgroundColor'] = `${style[item]}`
        }
        if(item === 'fontColor'){
            compilerStyle['color'] = `${style[item]}`
        }
    })
    return compilerStyle
}

export const IsMobile = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const GetOkaneLinkCreator = (token: string, oneMealId: number, serviceName: OkaneServiceNameEnums) => {
  return `${process.env.REACT_APP_OKANE_BASE_URL}/${serviceName}?token=${token}&serviceId=${oneMealId}`
}
