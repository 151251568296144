import React from 'react'
import IconComponent from './IconComp';
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import HeadingComponent from "./HeadingComponent/HeadingComponent";
import { HorizontalCardDto } from '../interfaces/componentsDtos/HorizontalCardComp';

export default function HorizontalCard(props: { data: HorizontalCardDto }) {

    const isSmallScreen = useSelector((state: RootState) => state.common.value.isSmallScreen)

    return (
        <div style={isSmallScreen ? styles.mainContainerSmall : styles.mainContainer}>
            <div style={{marginRight: isSmallScreen ? '12px' : '16px'}}>
                <IconComponent data={props.data?.image} inputStyle={styles.imageStyle}/>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignSelf: "center"}}>
                <HeadingComponent data={props.data.description.heading} inputStyles={{marginBottom: isSmallScreen ? '6px' : '12px'}}/>
                <HeadingComponent data={props.data.description.subHeading}/>
            </div>
        </div>
    );
}
const styles = {
    mainContainer: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        maxWidth: '480px',
        border: "2px solid #E6E6E6",
        borderRadius: '10px',
        padding: '32px 30px',
        marginBottom: '52px'
    },
    mainContainerSmall: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        border: '1px solid #F8F8F8',
        boxShadow: '0px 1px 10px rgba(214, 214, 214, 0.6)',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '20px'
    },
    heading: {
        padding: "10px"
    },
    imageStyle: {
        marginRight: '.5rem'
    }
}
