import { AddressRequestDto } from "../../../interfaces/address/Address.interface"
import { Area } from "../../../interfaces/address/Area.interface"
import { CitiesInterface } from "../../../interfaces/address/Cities.interface"
import { Localities } from "../../../interfaces/address/Localities.interface"
import { setAllArea, setAllCites, setAllLocality, setCreateNewAddress, setEditAddressData } from "../../reducers/address"
import store from "../../store"

export const SetAllCitesAction = (data: CitiesInterface) => {
    store.dispatch(setAllCites(data))
}
export const SetAllAreaAction = (data: Area) => {
    store.dispatch(setAllArea(data))
}
export const SetAllLocalityAction = (data: Localities) => {
    store.dispatch(setAllLocality(data))
}
export const SetEditAddressData = (data: AddressRequestDto) => {
    store.dispatch(setEditAddressData(data))
}
export const SetCreateNewAddress = (data: AddressRequestDto) => {
    store.dispatch(setCreateNewAddress(data))
}