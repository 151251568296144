import React from 'react'
import {CarousalCardDto} from '../interfaces/componentsDtos/CarousalCard';
import HeadingComponent from './HeadingComponent/HeadingComponent';
import IconComponent from './IconComp';
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import {FormatIncomingStyle} from "../utils/utils";

export default function CarouselCard(props: { data: CarousalCardDto }) {
    const isSmallScreen = useSelector((state: RootState) => state.common.value.isSmallScreen)

    const CmsStyle = FormatIncomingStyle(props.data)

    return (
        <div style={{...(isSmallScreen ? styles.mainContainerSmall : styles.mainContainer), ...CmsStyle}}>
            <div style={{marginBottom: isSmallScreen? '14px' : '60px'}}>
                <IconComponent data={props.data?.cardImage}/>
            </div>
            <HeadingComponent data={props?.data?.title} inputStyles={{textAlign: 'center'}}/>
        </div>
    );
}
const styles = {
    mainContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        height: '330px',
        width: '340px',
        margin: '88px 12px 0px 12px'
    },
    mainContainerSmall: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        height: '136px',
        width: '130px',
        margin: '36px 12px 0px 12px'
    }
}
