import {createSlice} from "@reduxjs/toolkit";

export const houseDetailsSlice = createSlice({
    name: 'houseDetails',
    initialState: {
        value: {
            userHouse: [],
        }
    },
    reducers: {
        setAllUserHouse: (state, action) => {
            state.value.userHouse = action.payload;
        },
    }
})

export const {setAllUserHouse} = houseDetailsSlice.actions;

export default houseDetailsSlice.reducer;
