import React from 'react'
import { Image } from '../interfaces/componentsDtos/HorizontalCardComp';
import { FormatIncomingStyle } from '../utils/utils';

export default function IconComponent(props: {data: Image, inputStyle?: any, onClickEvent?: any}) {
    const imageUrl = `${props.data.image?.data?.attributes?.url}`
    const CmsStyles = FormatIncomingStyle(props.data)
  return (
    <div style={{alignSelf:'center'}} onClick={props.onClickEvent}>
      <img style={{...CmsStyles,...props.inputStyle}} src={imageUrl} alt={"icons"} />
    </div>
  );
}
