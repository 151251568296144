import {Modal} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {useState} from "react";
import { FirebaseCleverTabRecordEvent } from "../../firebase";
import { EventNames } from "../../firebase/EventNames";
import { ScreenNames } from "../../firebase/ScreenNames";

interface InputProps {
    open: boolean;
    webPaymentLink: string;
    onClose: () => void;
}

export default function PaymentModal(props: InputProps) {
    const [payThroughApp, setPayThroughApp] = useState<boolean>(false);

    let strapiRes = useSelector((state: RootState) => state.strapi.value.getStrapiComponentsRes);
    const isSmallScreen = useSelector((state: RootState) => state.common.value.isSmallScreen)

    // @ts-ignore
    let downloadAppData: any = strapiRes?.data?.attributes?.DownloadAppContainer;
    let androidIcon = downloadAppData?.AndroidIcon?.image?.data?.attributes?.url;
    let iosIcon = downloadAppData?.IosIcon?.image?.data?.attributes?.url;
    let qrCodeIcon = downloadAppData?.Qrcode?.image?.data?.attributes?.url;

    let handlePayWeb = () => {
        FirebaseCleverTabRecordEvent(EventNames.PAY_BY_WEB, {
            screenName: ScreenNames.CHEFIT_ONE_MEAL_PAGE,
        });
        window.location.replace(props.webPaymentLink);
    }

    let handlePayApp = () => {
        FirebaseCleverTabRecordEvent(EventNames.PAY_BY_APP, {
            screenName: ScreenNames.CHEFIT_ONE_MEAL_PAGE,
        });
       setPayThroughApp(true);
    }

    let handleOnClose = () => {
        FirebaseCleverTabRecordEvent(EventNames.CLOSE_PAYMENT_MODAL, {
            screenName: ScreenNames.CHEFIT_ONE_MEAL_PAGE,
        });
        props.onClose()
     }

    return (
        <Modal open={props.open}>
            <div style={styles.mainContainer}>
                <div style={styles.wrapper}>
                    {
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}
                        >
                            {
                                payThroughApp ?
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: '12px'
                                        }}
                                        onClick={() => {
                                            setPayThroughApp(false)
                                            FirebaseCleverTabRecordEvent(EventNames.GO_BACK, {
                                                screenName: ScreenNames.CHEFIT_ONE_MEAL_PAGE,
                                            });
                                        }}
                                    >
                                        <img
                                            style={styles.leftArrow}
                                            src={require('../../assets/DownArrow.png')}
                                            alt={'Instagram'}
                                        />
                                        <p
                                            style={{
                                                fontWeight: '700',
                                                fontSize: '14px',
                                                margin: '0px',
                                                marginLeft: '8px',
                                                color: '#000000'
                                            }}
                                        >
                                            Go back
                                        </p>
                                    </div>
                                    :
                                    <div/>
                            }
                            <div onClick={handleOnClose}>
                                <img
                                    alt={'Cross Button'}
                                    style={{
                                        height: '12px',
                                        width: '12px'
                                    }}
                                    src={require('../../assets/cross_black.png')}
                                />
                            </div>
                        </div>
                    }
                    {
                        payThroughApp ?
                            isSmallScreen ?
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        border: '1px solid #DDDDDD',
                                        borderRadius: '8px',
                                        padding: '20px',
                                        alignItems: 'center'
                                    }}
                                >
                                    <p
                                        style={{
                                            fontWeight: '700',
                                            fontSize: '16px',
                                            margin: '0px'
                                        }}
                                    >
                                        Download our App
                                    </p>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <img
                                            src={androidIcon}
                                            alt={'PlayStore'}
                                            onClick={() => {
                                                window.open("https://play.google.com/store/apps/details?id=com.chefkart.app&hl=en_GB&gl=IN")
                                            }}
                                        />
                                        <img
                                            style={{
                                                marginLeft: '8px'
                                            }}
                                            src={iosIcon}
                                            alt={'AppStore'}
                                            onClick={() => {
                                                window.open("https://apps.apple.com/in/app/chefkart-your-personal-chef/id1569139823")
                                            }}
                                        />
                                    </div>
                                </div>
                                :
                                <div
                                    style={{
                                        padding: '24px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        src={qrCodeIcon}
                                        alt={'QrCode'}
                                        style={{
                                            height: '120px',
                                            width: '120px',
                                            marginBottom: '24px'
                                        }}
                                    />
                                    <p
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: '700',
                                            fontSize: '16px',
                                            margin: '0px'
                                        }}
                                    >
                                        Download App
                                    </p>
                                    <p
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            marginTop: '8px',
                                            maxWidth: '70%'
                                        }}
                                    >
                                        Scan the QR Code for a seamless ChefIT experience
                                    </p>
                                </div>
                            :
                            <>
                                <p
                                    style={{
                                        fontWeight: '700',
                                        fontSize: '20px',
                                        textAlign: 'center',
                                        margin: '0px',
                                        color: '#1C1C1C'
                                    }}
                                >
                                    One Last Step
                                </p>
                                <p
                                    style={{
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        textAlign: 'center',
                                        margin: '8px 0px 24px 0px',
                                        color: '#888888'
                                    }}
                                >
                                    To activate your booking please choose a mode of payment
                                </p>

                                <div style={styles.bigButton} onClick={handlePayApp}>
                                    <div>
                                        <p
                                            style={{
                                                fontWeight: '700',
                                                fontSize: '16px',
                                                margin: '0px',
                                                color: '#242424'
                                            }}
                                        >
                                            Pay via app
                                        </p>
                                        <p
                                            style={{
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                margin: '0px',
                                                color: '#979797'
                                            }}
                                        >
                                            Track payments, refunds & much more
                                        </p>
                                    </div>
                                    <img
                                        style={styles.rightArrow}
                                        src={require('../../assets/DownArrow.png')}
                                        alt={'Instagram'}
                                    />
                                </div>

                                <div style={{...styles.bigButton, marginTop: '18px'}} onClick={handlePayWeb}>
                                    <div>
                                        <p
                                            style={{
                                                fontWeight: '700',
                                                fontSize: '16px',
                                                margin: '0px',
                                                color: '#242424'
                                            }}
                                        >
                                            Pay via web
                                        </p>
                                        <p
                                            style={{
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                margin: '0px',
                                                color: '#979797'
                                            }}
                                        >
                                            Quick & easy online payment
                                        </p>
                                    </div>
                                    <img
                                        style={styles.rightArrow}
                                        src={require('../../assets/DownArrow.png')}
                                        alt={'Instagram'}
                                    />
                                </div>
                            </>
                    }
                </div>
            </div>
        </Modal>
    )
}

const styles = {
    mainContainer: {
        display: 'flex' as 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    wrapper: {
        backgroundColor: '#ffffff',
        borderRadius: '18px',
        padding: '20px',
        maxWidth: '400px',
        margin: '0px 30px',
        width: '100%'
    },
    bigButton: {
        borderRadius: '10px',
        border: '1px solid #BFBFBF',
        display: 'flex' as 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
        padding: '12px 18px',
        alignItems: 'center',
    },
    rightArrow: {
        transform: 'rotate(-90deg)',
        height: '8px',
        width: '12px'
    },
    leftArrow: {
        transform: 'rotate(90deg)',
        height: '8px',
        width: '12px'
    },
}
