import React from 'react'
import IconComponent from '../components/IconComp';
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import HeadingComponent from "../components/HeadingComponent/HeadingComponent";

export default function DownloadAppContainer(props: { item: any }) {
    const components: any = props.item.value;

    const isSmallScreen = useSelector((state: RootState) => state.common.value.isSmallScreen)

    return (
        <div className={'common-container'}
             style={{
                 paddingTop: isSmallScreen ? '18px' : "60px",
                 paddingBottom: isSmallScreen ? '24px' : "120px",
                 backgroundColor: components.backgroundColor
            }}
        >
            <div
                style={{
                    maxWidth: isSmallScreen ? '100%' : '75%',
                    margin: isSmallScreen ? '0px 20px' : '0px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    background: isSmallScreen ? '#ffffff' : '#F7F7F7',
                    borderRadius: isSmallScreen ? '8px' : '20px',
                    border: isSmallScreen ? '1px solid #DDDDDD' : '0px',
                    boxShadow: isSmallScreen ? '0px 1px 6px rgba(0, 0, 0, 0.1)' : 'none',
                    padding: isSmallScreen ? '18px' : '32px 55px'
                }}
            >
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <HeadingComponent
                        data={components?.description?.heading}
                        inputStyles={{margin: '0px'}}
                    />
                    {
                        !isSmallScreen &&
                        <HeadingComponent data={components?.description?.subHeading}/>
                    }
                </div>

                {isSmallScreen ? (
                    <div style={styles.mobileIconContainer}>
                        <IconComponent
                            data={components?.AndroidIcon}
                            inputStyle={{marginRight: "28px"}}
                            onClickEvent={()=>window.open("https://play.google.com/store/apps/details?id=com.chefkart.app&hl=en_GB&gl=IN")}
                        />
                        <IconComponent
                            data={components?.IosIcon}
                            onClickEvent={()=>window.open("https://apps.apple.com/in/app/chefkart-your-personal-chef/id1569139823")}
                        />
                    </div>
                ) : (
                    <IconComponent data={components?.Qrcode}/>
                )}
            </div>
        </div>
    );
}
const styles = {
    mainContainer: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
        borderRadius: '8px',
        padding: '1rem 1rem',
        maxWidth: '100%',
        width: '100%',
        alignItem: 'center'
    },
    heading: {
        padding: "10px"
    },
    imageStyle: {
        alignSelf: 'center',
        marginRight: '.5rem'
    },
    mobileIconContainer: {
        flex: '1',
        display: 'flex',
        justifyContent: 'flex-end'
    }
}
