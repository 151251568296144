export default function BannerContainer(props: { item: any }) {
    const component: any = props.item.value;
    let bannerImage = component?.bannerImage?.data;
    let bannerImageUrl =
        bannerImage &&
        `${bannerImage?.attributes?.url}`;

    return (
        <div className={'common-container'}>
                {bannerImage && (
                    <img
                        key={component?.position}
                        src={bannerImageUrl}
                        style={styles.bannerImage}
                        alt={"hero-img"}
                    />
                )}
        </div>
    );
}
const styles = {
    bannerImage: {
        width: "100%",
        height: "auto",
    },
};
