import React, {useCallback, useEffect, useState} from 'react'
import StrapiService from '../../services/strapiService'
import BuilderFunction from '../../builderComponents/FinderFunction'
import {useLocation} from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import { PageNamesEnum } from '../../enums/PageName'
import {useDispatch} from "react-redux";
import {setGetStrapiComponentRes} from "../../store/reducers/strapiComponent";

const strapiService = new StrapiService()

export default function ChefItPage(props: {pageName: PageNamesEnum}) {
    const [loading, setLoading] = useState(true)
    const location = useLocation()
    let pageLocation = location.pathname.split('/')
    let [params] = useState(pageLocation[2])
    let [components, setComponents] = useState<any[]>([]);
    const dispatch = useDispatch()

    const getComponents = useCallback(async () => {
        try {
            let data: any;
            if(props.pageName === PageNamesEnum.CHEF_IT){
                data = await strapiService.getAllChefItComponent(params);
            }
            else{
                data = await strapiService.getAllCleanItComponent(params);
            }
            dispatch(setGetStrapiComponentRes(data))
            let items: any = [];
            Object.keys(data?.data?.attributes).forEach((val) => {
                if (data?.data?.attributes[val]) {
                    items.push({key: val.toLowerCase(), value: data?.data?.attributes[val]});
                }
            });
            items.sort((a: any, b: any) => a?.value?.position - b?.value?.position);
            setComponents(items);
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }, [params, props.pageName, dispatch]);

    useEffect(() => {
        getComponents()
    }, [getComponents])

    return (
        <div>
            {
                loading ?
                    (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height:"100vh",
                            }}
                            >
                            <CircularProgress />
                            </Box>
                    )
                    :
                    (
                        <div>
                            {
                                !!components &&
                                Array.isArray(components) &&
                                components.length > 0 &&
                                components.map((item) => <BuilderFunction item={item} key={item.key}/>)
                            }
                        </div>
                    )
            }
        </div>
    );
}
