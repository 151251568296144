import HttpClient from "../HttpClient";
import Customer from "../interfaces/customer";
import { SetAuthDetailsAction, SetCustomerDetailsAction } from "../store/actions/loginAction/setAuthDetailsActions";
import { saveToSessionStorage } from "../utils/utils";
export default class CustomerService {
    
    async getOtp(mobile: string) {
        let payload = {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            mobile,
            hash: "hash"
        }
        const res = await HttpClient.post('/auth/v2/send/otp', payload)
        return res?.data;
    }

    async verifyOtp(mobile: string, otp: string) {
        let payload = {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            mobile,
            otp
        }
        const res = await HttpClient.post('/auth/v2/verify/otp', payload)
        SetAuthDetailsAction(res.data)
        saveToSessionStorage("token", res.data.accessToken);
        return res;
    }

    async getCustomerDetails(): Promise<Customer | null> {
        const res = await HttpClient.get('/customer')
        SetCustomerDetailsAction(res?.data?.data)
        return res?.data?.data
    }

    async updateCustomerDetails(fname: string, lname: string): Promise<Customer | null> {
        let payload = {
            fname,
            lname
        }
        const res = await HttpClient.patch('/v2/customer/profile', payload)
        SetCustomerDetailsAction(res?.data)
        return res?.data
    }
}