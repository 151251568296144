import {useSelector} from "react-redux";
import {RootState} from "../store/store";

export default function Footer(props: {item: any}){
    const components: any = props.item.value;
    const isSmallScreen = useSelector((state: RootState) => state.common.value.isSmallScreen)

    return (
        <div className={'common-container'}
             style={{
                 marginTop: isSmallScreen ? '24px' : '120px',
                 padding: isSmallScreen ? '24px 0px' : '90px 0px',
                 backgroundColor: '#323232'
             }}
        >
            <div
                style={{
                    maxWidth: isSmallScreen ? '100%' : '75%',
                    margin: isSmallScreen ? '0px 20px' : '0px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    <a
                        style={{
                            ...style.hyperLink,
                            fontSize: isSmallScreen ? '10px' : '28px',
                            marginRight: isSmallScreen ? '12px' : '58px'
                        }}
                        rel="noreferrer"
                        target="_blank"
                        href={components.termsOfUseLink}
                    >
                        Terms & Condition
                    </a>
                    <a
                        style={{
                            ...style.hyperLink,
                            fontSize: isSmallScreen ? '10px' : '28px',
                        }}
                        rel="noreferrer"
                        target="_blank"
                        href={components.privacyPolicyLink}
                    >
                        Privacy Policy
                    </a>
                </div>

                <div>
                    <a
                        style={style.hyperLink}
                        rel="noreferrer"
                        target="_blank"
                        href={'https://www.linkedin.com/company/chefkart'}
                    >
                        <img
                            style={isSmallScreen? style.imgSmall : style.imgBig} src={require('../assets/linkedin_icon.png')}
                            alt={'Linkedin'}
                        />
                    </a>
                    <a
                        style={style.hyperLink}
                        rel="noreferrer"
                        target="_blank"
                        href={'https://www.facebook.com/chefkart/'}
                    >
                        <img
                            style={isSmallScreen? style.imgSmall : style.imgBig} src={require('../assets/facebook_icon.png')}
                            alt={'Facebook'}
                        />
                    </a>
                    <a
                        style={style.hyperLink}
                        rel="noreferrer"
                        target="_blank"
                        href={'https://www.instagram.com/thechefkart/'}
                    >
                        <img
                            style={isSmallScreen? style.imgSmall : style.imgBig} src={require('../assets/instagram_icon.png')}
                            alt={'Instagram'}
                        />
                    </a>
                </div>
            </div>
        </div>
    )
}

const style = {
    imgBig: {
        height: '36px',
        marginLeft: '36px'
    },
    imgSmall: {
        height: 16,
        marginLeft: '12px'
    },
    hyperLink: {
        textDecoration: 'none',
        outline: 'none',
        color: '#ffffff',
        cursor: 'pointer',
        textDecorationLine: 'underline',
    }
}
