import {createSlice} from "@reduxjs/toolkit";

export const commonSlice = createSlice({
    name: 'customerDetails',
    initialState: {
        value: {
            isSmallScreen: window.innerWidth < 850
        }
    },
    reducers: {
        setIsSmallScreen: (state, action: {payload: boolean}) => {
            state.value.isSmallScreen = action.payload;
        }
    }
})

export const {setIsSmallScreen} = commonSlice.actions;

export default commonSlice.reducer;
