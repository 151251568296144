import HttpClient from "../HttpClient";

export default class StarCleanerService {
    async createStarCleanerVisit(houseId: number, payload: any){
        const res = await HttpClient.post(`/v1/customer/starcleaner/house/${houseId}`, payload);
        return res?.data;
    }

    async getStarCleanerVisitPrice(){
        const res = await HttpClient.get(`/v1/customer/starcleaner/price`);
        return res?.data;
    }
}
