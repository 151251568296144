import React, {useCallback, useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import './App.css';
import CustomSnackbar from './components/CustomeSnackBar';
import ChefItPage from './pages/ChefItPage/ChefItPage';
import {useDispatch} from "react-redux";
import {setIsSmallScreen} from "./store/reducers/common";
import { PageNamesEnum } from './enums/PageName';
import LandingPage from './pages/landingPage/LandingPage';

function App() {

    const dispatch = useDispatch()

    let mediaHandle = useCallback(() => {
        if (window.innerWidth < 850) {
            dispatch(setIsSmallScreen(true))
        } else {
            dispatch(setIsSmallScreen(false))
        }
    }, [dispatch]);

    useEffect(() => {
        window.addEventListener("resize", mediaHandle)
    }, [mediaHandle])

    return (
        <div>
            <CustomSnackbar/>
            <Routes>
                <Route path="/login/*" element={<LandingPage />}/>
                <Route path="/chefit/*" element={<ChefItPage pageName={PageNamesEnum.CHEF_IT}/>}/>
                <Route path="/cleanit/*" element={<ChefItPage pageName={PageNamesEnum.CLEAN_IT}/>}/>
            </Routes>
        </div>
    );
}

export default App;
