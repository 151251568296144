import CollapsibleHeadingComponent from "../components/CollapsibleHeading";
import HeadingComponent from "../components/HeadingComponent/HeadingComponent";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";

export default function CollapsibleCardContainer(props: { item: any }) {
    const component: any = props.item.value;

    const isSmallScreen = useSelector((state: RootState) => state.common.value.isSmallScreen)

    return (
        <div className={'common-container'}
             style={{
                 paddingTop: isSmallScreen ? "16px" : "60px",
                 paddingBottom: isSmallScreen ? "36px" : "120px",
                 backgroundColor: component.backgroundColor
             }}
        >
            <div
                style={{
                    maxWidth: isSmallScreen ? '100%' : '75%',
                    margin: isSmallScreen ? '0px 20px' : '0px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {component && (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <HeadingComponent
                            data={component.heading}
                            inputStyles={{textAlign: 'center', marginBottom: isSmallScreen ? '16px' : '60px'}}
                        />
                        {
                            component.data &&
                            component.data.map((e: any, i: number) => {
                                return <CollapsibleHeadingComponent data={e} key={i}/>;
                            })
                        }
                    </div>
                )}
            </div>
        </div>
    );
}
