import React from 'react'
import { HeadingDto } from '../../interfaces/componentsDtos/DescriptionComp';
import { FormatIncomingStyle } from '../../utils/utils';

export default function HeadingComponent(props: {data?: HeadingDto, inputStyles?: any}) {
  const CmsStyle = FormatIncomingStyle(props.data)
  return (
    <p style={{...CmsStyle,...styles.heading, ...props.inputStyles}}>{props?.data?.content}</p>
  );
}
const styles = {
  heading: {
    margin : '0'
  }
}
