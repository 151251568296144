import React, {useCallback, useEffect, useState} from 'react'
import StrapiService from '../../services/strapiService'
import {useLocation, useNavigate} from 'react-router-dom'
import { Box, CircularProgress, FormControl, TextField } from '@mui/material'
import {useDispatch, useSelector} from "react-redux";
import { setLandingPageComponentRes} from "../../store/reducers/strapiComponent";
import IconComponent from '../../components/IconComp';
import { RootState } from '../../store/store';
import { EventBusEventsEnum } from '../../enums/EventBusEventEnums';
import EventBus from '../../EventBus';
import CustomerService from '../../services/customerService';
import { FirebaseCleverTabRecordEvent, FirebaseCleverTabSetProfile } from '../../firebase';
import HeadingComponent from '../../components/HeadingComponent/HeadingComponent';
import { EventNames } from '../../firebase/EventNames';
import { ScreenNames } from '../../firebase/ScreenNames';
import CampaignService from '../../services/campaignService';
import { CustomSnackbarTypes } from '../../components/CustomeSnackBar';

const strapiService = new StrapiService()
const customerService = new CustomerService();
const campaignService = new CampaignService();
declare const gtag: any;

export default function LandingPage() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());
    const [loading, setLoading] = useState(true)
    const location = useLocation()
    let pageLocation = location.pathname.split('/')
    let [params] = useState(pageLocation[2])
    const [data, setData] = useState<any>();
    const dispatch = useDispatch()
    const isSmallScreen = useSelector((state: RootState) => state.common.value.isSmallScreen)
    const [mobile, setMobile] = useState<string>("")
    const [otp, setOtp] = useState<string>("")
    const [otpResponse, setOtpResponse] = useState<boolean>(false)
    const [verifyOtp, setVerifyOtp] = useState<any>(null)
    const navigate = useNavigate();

    const getOtpHandle = async () => {
      if (mobile?.length < 10 || mobile?.length > 10) {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: 'Please fill mobile number and name properly',
            type: CustomSnackbarTypes?.ERROR
        });
        return
      }
      setOtp("")
      FirebaseCleverTabRecordEvent(EventNames.USER_GET_OTP, {
        screenName: ScreenNames.CHEFIT_LOGIN_PAGE,
      });
      const res = await customerService.getOtp(mobile);
      if (res) {
        setOtpResponse(true);
      }
    };

    const verifyOtpHandle = async () => {
        if (otp) {
            try {
                let verifiedRes = await customerService.verifyOtp(mobile, otp);
                gtag('event', 'conversion', {'send_to': 'AW-443347593/Cs_ZCKSLzY0YEInls9MB'})
                setVerifyOtp(verifiedRes);
                FirebaseCleverTabRecordEvent(EventNames.USER_VERIFY_OTP_CLICKED, {
                  screenName: ScreenNames.CHEFIT_LOGIN_PAGE,
                });
                let customer = await customerService.getCustomerDetails();
                FirebaseCleverTabSetProfile(customer!)
                if (Object.keys(queryParams)?.length > 0) {
                  campaignService.postCampaign({data: queryParams})
                }
                navigate(`/${data?.form?.button?.href.toLowerCase()}/${data?.form?.button?.hrefId}`)
            } catch (err) {
                console.log(err);
            }
        }
    };

    const getComponents = useCallback(async () => {
        try {
            let data: any = await strapiService.getLandingPageComponent(params);
            dispatch(setLandingPageComponentRes(data))
            setData(data?.data?.attributes)
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }, [params, dispatch]);

    useEffect(() => {
        getComponents()
    }, [getComponents])

    useEffect(() => {
      FirebaseCleverTabRecordEvent(EventNames.LANDING_PAGE_VIEW, {
        screenName: ScreenNames.CHEFIT_LOGIN_PAGE
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: isSmallScreen ? "column-reverse" : "row",
            }}
          >
            <div
              style={{
                width: isSmallScreen ? "100%" : "50%",
                display: "flex",
                height: isSmallScreen ? "unset" : "100vh",
                justifyContent: "center",
                alignItems: "center",
                margin: isSmallScreen ? "20px 0" : "0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "min-content",
                  width: isSmallScreen ? "80%" : "450px",
                  padding: "0 24px",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                >
                  <HeadingComponent data={data?.form?.heading} />
                  <HeadingComponent data={data?.form?.subHeading} />
                </div>
                <div>
                  <p style={styles.label}>Mobile Number*</p>
                  <FormControl fullWidth size={"medium"}>
                    <TextField
                      type="tel"
                      id="mobile"
                      disabled={otpResponse}
                      onChange={(e: any) => {
                        setMobile(e.target.value);
                      }}
                      placeholder="10 digit phone no."
                      inputProps={{
                        style: {
                          padding: 12,
                        },
                      }}
                    />
                  </FormControl>
                  {otpResponse && <span style={styles.otpSendMsg}>OTP sent</span>}
                  {!otpResponse && (
                    <button
                      style={styles.submitBtn}
                      className={'btn'}
                      disabled={mobile.length !== 10}
                      onClick={() => {
                        getOtpHandle();
                      }}
                    >
                      {data?.form?.button?.content}
                    </button>
                  )}
                </div>
                {!verifyOtp && otpResponse && (
                  <div>
                    <p style={styles.label}>Enter OTP*</p>
                    <FormControl fullWidth size={"medium"}>
                      <TextField
                        type="tel"
                        id="otp"
                        value={otp}
                        onChange={(e: any) => setOtp(e.target.value)}
                        placeholder="Enter the OTP"
                        inputProps={{
                          style: {
                            padding: 12,
                          },
                        }}
                      />
                    </FormControl>
                    <button
                      style={styles.submitBtn}
                      className={'btn'}
                      disabled={otp.length !== 6}
                      onClick={() => {
                        verifyOtpHandle();
                      }}
                    >
                      {data?.form?.button?.content}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div style={{ width: isSmallScreen ? "100%" : "50%" }}>
              {isSmallScreen ? (
                <IconComponent
                  data={{ image: data?.hero?.mobileImage } as any}
                  inputStyle={styles.mobileImage}
                />
              ) : (
                <IconComponent data={{ image: data?.hero?.webImage } as any} 
                  inputStyle={styles.webImage}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
}
const styles = {
    label: {
        fontSize: "15px",
        fontWeight: 600,
        color: '#52525C',
        margin: '20px 0px 8px 0px'
    },
    submitBtn: {
      backgroundColor: '#000000',
      marginTop: '25px',
      padding: '12px',
      fontSize: '20px',
      lineHeight: '24px',
      borderRadius: '8px',
      border: 'none',
      fontWeight: '700',
      color: '#ffffff',
      minWidth: "100%",
      cursor: 'pointer',
      fontFamily: "Open Sans"
    },
    otpSendMsg: {
      color: "green",
      paddingLeft: "6px",
      fontSize: "14px",
      fontWeight: "600"
    },
    mobileImage:{
      width: "100%",
      height: "auto"
    },
    webImage: {
      width: "100%",
      height: "100vh"
    }
}

