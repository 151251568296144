export enum ComponentNames {
    HORIZONTAL_CARD_CONTAINER = 'horizontalcardconatiner',
    CARD_CONTAINER = 'cardcontainer',
    COLLAPSIABLE_CARD_CONTAINER =  'collapsiblecardcontainer',
    HERO = 'hero',
    BANNER = 'banner',
    DOWNLOAD_APP_CONTAINER = 'downloadappcontainer',
    TESTIMOIALS = 'textimonialcontainer',
    FOOTER = 'footer'
}
