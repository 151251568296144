import HttpClient from "../HttpClient";
import { Address, AddressRequestDto } from "../interfaces/address/Address.interface";
import { Area } from "../interfaces/address/Area.interface";
import { CitiesInterface } from "../interfaces/address/Cities.interface";
import { Localities } from "../interfaces/address/Localities.interface";
import { SetAllAreaAction, SetAllCitesAction, SetAllLocalityAction, SetCreateNewAddress, SetEditAddressData } from "../store/actions/addressAction/addressAction";
import { SetAllUserHouseAction } from "../store/actions/houseAction/houseActions";

export default class HouseService {
    async getAllCities(): Promise<CitiesInterface[]> {
        const res = await HttpClient.get("/houses/cities");
        SetAllCitesAction(res?.data);
        return res?.data;
    }

    async getAllHouseForUser(customerId: string): Promise<Address[]> {
        const res = await HttpClient.get(`/centaurus/user/customer/${customerId}/houses`)
        SetAllUserHouseAction(res?.data)
        return res?.data
    }

    async getAllAreaForCity(cityId: string): Promise<Area[]> {
        const res = await HttpClient.get(`houses/${cityId}/areas`)
        SetAllAreaAction(res?.data)
        return res?.data
    }

    async getAllLocalityForArea(areaId: string): Promise<Localities[]> {
        const res = await HttpClient.get(`/houses/${areaId}/localities`)
        SetAllLocalityAction(res?.data)
        return res?.data
    }

    async createHouse(payload: AddressRequestDto){
        const res = await HttpClient.post(`v2/customer/houses`, payload)
        console.log(res?.data)
        SetCreateNewAddress(res?.data);
        return res?.data
    }

    async editHouse(houseId: number, payload: AddressRequestDto){
        const res = await HttpClient.patch(`/v2/customer/houses/${houseId}`, payload)
        SetEditAddressData(res?.data);
        return res?.data
    }

}
